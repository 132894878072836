export const GA4_TRACKING_ID = process.env.GA4_TRACKING_ID;
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const GTM_ID = process.env.GTM_ID;

type EventParams = {
  action: string;
  category: string;
  label: string;
  value: number;
  currency?: string;
};

class GoogleAnalyticsManager {
  curentUser: Record<string, any>;

  constructor() {}

  setUserProperties(curentUser) {
    if (curentUser) {
      this.curentUser = curentUser;
      this.setGAUser(curentUser);
    }
  }

  setGAUser(userId) {
    if (window.gtag) {
      window.gtag('set', 'user_id', userId);
    }
  }

  login(data = {}) {
    this.sendEvent('login', data);
  }

  registration_begin() {
    this.sendEvent('registration_begin', {});
    window.dataLayer?.push({
      event: 'registration_begin',
    });
  }
  registration_step1Complete({ registration_source }) {
    this.sendEvent('registration_step1_complete', {
      registration_source,
    });
    window.dataLayer.push({
      event: 'registration_step1_complete',
      registration_source,
    });
  }

  registration_step2Complete() {
    this.sendEvent('registration_step2_complete', {});
    window.dataLayer?.push({
      event: 'registration_step2_complete',
    });
  }

  registration_campaignCodeUsed(data) {
    this.sendEvent('registration_campaign-code_used', data);
  }

  registration_trialPaymentMethodAdded(data) {
    this.sendEvent('registration_payment_method_added', data);
  }

  trial_started({ plan, amount, currency }) {
    this.sendEvent('trial_started', {
      plan,
      amount,
      currency,
    });
    window.dataLayer?.push({ event: 'trial_started', plan, amount, currency });
  }

  trial_plan_selected({ plan, amount = null, currency = null }) {
    this.sendEvent('trial_plan_selected', {
      plan,
      amount,
      currency,
    });
  }

  //
  book_event(data) {
    this.sendEvent('book_event', data);
    window.dataLayer?.push({
      ...data,
      event: 'book_event',
    });
  }

  program_begin(data) {
    this.sendEvent('program_begin', data);
  }

  program_complete(data) {
    this.sendEvent('program_complete', data);
  }

  challenge_begin(data) {
    this.sendEvent('challenge_begin', data);
  }

  challenge_complete(data) {
    this.sendEvent('challenge_complete', data);
  }

  course_begin(data) {
    this.sendEvent('course_begin', data);
  }

  course_complete(data) {
    this.sendEvent('course_complete', data);
  }

  bundle_preRegister(data) {
    this.sendEvent('bundle_pre-register', data);
  }

  bundle_begin(data) {
    this.sendEvent('bundle_begin', data);
  }

  recommendations_begin() {
    this.sendEvent('recommendations_begin', {});
  }

  recommendations_complete() {
    this.sendEvent('recommendations_complete', {});
  }

  addto_playlist(data) {
    this.sendEvent('addto_playlist', data);
  }

  favorite_session(data) {
    this.sendEvent('favorite_session', data);
  }

  favorite_playlist(data) {
    this.sendEvent('favorite_playlist', data);
  }

  search_global(data) {
    this.sendEvent('search_global', data);
  }

  search_library(data) {
    this.sendEvent('search_library', data);
  }

  //
  purchase_subscriptionBegin(data) {
    this.sendEvent('purchase_subscription_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_subscription_begin',
    });
  }
  purchase_subscriptionComplete(data) {
    this.sendEvent('purchase_subscription_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_subscription_complete',
    });
  }
  purchase_subscriptionFail(data) {
    this.sendEvent('purchase_subscription_fail', data);
  }

  //
  purchase_LiveBegin(data) {
    this.sendEvent('purchase_live_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_live_begin',
    });
  }

  purchase_LiveComplete(data) {
    this.sendEvent('purchase_live_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_live_complete',
    });
  }

  purchase_LiveVoucherComplete(data) {
    this.sendEvent('purchase_live_voucher_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_live_voucher_complete',
    });
  }

  purchase_LiveFail(data) {
    this.sendEvent('purchase_live_fail', data);
  }

  //
  purchase_courseBegin(data) {
    this.sendEvent('purchase_course_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_course_begin',
    });
  }
  purchase_courseComplete(data) {
    this.sendEvent('purchase_course_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_course_complete',
    });
  }
  purchase_courseFail(data) {
    this.sendEvent('purchase_course_fail', data);
  }

  // custom access
  purchase_CustomAccessBegin(data) {
    this.sendEvent('purchase_custom_access_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_custom_access_begin',
    });
  }
  purchase_CustomAccessComplete(data) {
    this.sendEvent('purchase_custom_access_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_custom_access_complete',
    });
  }
  purchase_CustomAccessFail(data) {
    this.sendEvent('purchase_custom_access_fail', data);
  }

  // Wellness access
  purchase_WellnessAccessBegin(data) {
    this.sendEvent('purchase_wellness_access_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_wellness_access_begin',
    });
  }
  purchase_WellnessAccessComplete(data) {
    this.sendEvent('purchase_wellness_access_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_wellness_access_complete',
    });
  }
  purchase_WellnessAccessFail(data) {
    this.sendEvent('purchase_wellness_access_fail', data);
  }

  // LivePackage
  purchase_LivePackageBegin(data) {
    this.sendEvent('purchase_live_package_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_live_package_begin',
    });
  }
  purchase_LivePackageComplete(data) {
    this.sendEvent('purchase_live_package_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_live_package_complete',
    });
  }
  purchase_LivePackageFail(data) {
    this.sendEvent('purchase_live_package_fail', data);
  }

  // Bundle
  purchase_bundleBegin(data) {
    this.sendEvent('purchase_bundle_begin', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_bundle_begin',
    });
  }
  purchase_bundleComplete(data) {
    this.sendEvent('purchase_bundle_complete', data);
    window.dataLayer?.push({
      ...data,
      event: 'purchase_bundle_complete',
    });
  }
  purchase_bundleFail(data) {
    this.sendEvent('purchase_bundle_fail', data);
  }

  // subscription cancelation
  subscription_cancel(data) {
    this.sendEvent('subscription_cancel', data);
    window.dataLayer?.push({
      ...data,
      event: 'subscription_cancel',
    });
  }
  //

  sendEvent(event, data = {}) {
    try {
      // for GA4, send user data on all events
      window.gtag?.('event', event, {
        ...this.curentUser,
        ...data,
        send_to: GA4_TRACKING_ID,
      });
    } catch (ex) {
      //
    }
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event = (params: EventParams) => {
    const { action, category, label, value, currency } = params;
    try {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
        currency,
        send_to: GA_TRACKING_ID,
      });
    } catch (ex) {
      //
    }
  };
}

export default GoogleAnalyticsManager;
