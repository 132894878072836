import React, { useContext, useReducer, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import { Body, Footer, Header, Modal } from './base';
import {
  SubscriptionStatus,
  useChangeUserPreferencesMutation,
} from '@gql/generated';
import { CountryCombobox } from '@components/form/CountryCombobox';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from 'src/ui/select';
import { usePreference } from '@lib/hooks/usePreference';
import CookieManager from '@lib/CookieManager';
import { CURRENCY_COOKIE, LANGUAGES } from '@lib/constants';
import { useApolloClient } from '@apollo/client';
import { Button } from '@components/ButtonV2';
import LargeTooltip from '@components/LargeTooltip';
import { cn } from '@lib/tw';
import useFlags from '@lib/hooks/useFlags';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';

const Option = ({ name, value, title, selected, desc = null }) => {
  return (
    <label className="relative">
      <input
        className="peer opacity-0 pointer-events-none absolute top-0 left-0"
        type="radio"
        name={name}
        value={value}
        defaultChecked={selected}
        // onChange={() => {
        //   // just to silence react errors
        // }}
      />
      <div className="relative rounded-lg border-2 border-neutral-200/80 p-4 cursor-pointer peer-checked:border-primary peer-checked:border-2">
        <p className="text-sm md:text-lg font-semibold p-0 m-0 text-black">
          {title}
        </p>
        {desc ? (
          <span className="text-xs md:text-base text-neutral-400 font-medium">
            {desc}
          </span>
        ) : null}
      </div>
    </label>
  );
};

type CurrencyLanguageModalProps = {
  onClose(): void;
  onCurrencyChange(): void;
};

export const CurrencyLanguageModal = ({
  onClose,
  onCurrencyChange,
}: CurrencyLanguageModalProps) => {
  const lang = useContext(LanguageContext);
  const [currentUser] = useContext(UserContext);
  const apolloClient = useApolloClient();
  const [updateUserPreference] = useChangeUserPreferencesMutation();
  const [loading, setLoading] = useState(false);
  const userPreference = usePreference();
  const [state, dispatch] = useReducer(
    (state, action) => {
      return {
        ...state,
        ...action,
      };
    },
    {
      currency: userPreference.currency,
      language: currentUser?.emailLanguage,
      country: currentUser?.country,
      countryCode: currentUser?.countryCode,
    }
  );

  const savePreference = async () => {
    try {
      setLoading(true);
      userPreference.setCurrency(state.currency);
      if (
        currentUser?.id &&
        (currentUser.preferredCurrency !== state.currency ||
          currentUser.emailLanguage !== state.language ||
          currentUser.countryCode !== state.countryCode)
      ) {
        await updateUserPreference({
          variables: {
            preferredCurrency: state.currency,
            emailLanguage: state.language,
            country: state.country,
            countryCode: state.countryCode,
          },
        });
      } else {
        CookieManager.set({ key: CURRENCY_COOKIE, value: state.currency });
      }
      await apolloClient.resetStore();
      onCurrencyChange();
    } catch (ex) {
      //
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal open={true} handleClose={onClose}>
      <Header
        className="md:absolute top-0 left-0 w-full"
        handleClose={onClose}
      />
      <Body>
        <div className="md:pt-4">
          <form
            onChange={e => {
              if (
                e.target instanceof HTMLInputElement &&
                ['language', 'currency'].includes(e.target.name) &&
                e.target.checked
              ) {
                dispatch({ [e.target.name]: e.target.value });
              }
            }}
          >
            <div className="md:py-4">
              <p className="text-xl m-0">
                <Trans id="currency_model.title" />
              </p>
              <div className="grid grid-cols-2 gap-4 py-4">
                <Option
                  title="Swedish Kronor"
                  desc="(SEK / kr)"
                  value="SEK"
                  name="currency"
                  selected={state.currency === 'SEK'}
                />
                <Option
                  title="Euro"
                  desc="(EUR / €)"
                  value="EUR"
                  name="currency"
                  selected={state.currency === 'EUR'}
                />
              </div>
              {currentUser?.subscription?.status ===
                SubscriptionStatus['Active'] &&
              currentUser?.paymentTypeRenews ? (
                <p className="py-4 px-6 text-center bg-[#D9E8E7] rounded m-0">
                  <Trans id="currency_model.change_disclaimer" />
                  <Link
                    className="text-primary font-semibold px-1"
                    href={`/${lang}/me/settings/subscriptions`}
                  >
                    <Trans id="currency_model.manage_subscription" />
                  </Link>
                </p>
              ) : null}
            </div>
            {currentUser?.id ? (
              <>
                <div className="py-4">
                  <p className="text-xl m-0">
                    <Trans id="currency_model.email_language_label" />{' '}
                    <LargeTooltip>
                      {t({ id: 'currency_model.email_language_tooltip' })}
                    </LargeTooltip>
                  </p>
                  <div className="grid grid-cols-2 gap-4 py-4">
                    <Option
                      title={
                        <Trans id="currency_model.email_language_swidish" />
                      }
                      value="se"
                      name="language"
                      selected={state.language === 'se'}
                    />
                    <Option
                      title={
                        <Trans id="currency_model.email_language_english" />
                      }
                      value="en"
                      name="language"
                      selected={state.language === 'en'}
                    />
                    {/* <Option
                      title="Norsk"
                      desc="Norwegian"
                      value="no"
                      name="language"
                      selected={state.language === 'no'}
                    /> */}
                    {/* <Option
                      title="Suomen"
                      desc="Finnish"
                      value="fi"
                      name="language"
                      selected={state.language === 'fi'}
                    /> */}
                  </div>
                </div>
                <div className="py-4">
                  <p className="text-xl m-0">
                    <Trans id="currency_model.country_label" />
                  </p>
                  <div className="py-4 -mt-2">
                    <CountryCombobox
                      defaultSelectedCountryCode={currentUser?.countryCode}
                      onChange={country => {
                        dispatch({
                          country: country.label,
                          countryCode: country.code,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </Body>
      <Footer>
        <Button
          className="w-full justify-center"
          onClick={savePreference}
          loading={loading}
        >
          <span className="text-sm">
            <Trans id="currency_model.save_changes" />
          </span>
        </Button>
      </Footer>
    </Modal>
  );
};

type CurrencyButtonProps = {
  className?: string;
  shortVersion?: boolean;
  onCurrencyChange?(): void;
};
export const CurrencyButton = (props: CurrencyButtonProps) => {
  const {
    className = '',
    shortVersion = false,
    onCurrencyChange = null,
  } = props;
  const [flag] = useFlags();
  const [open, setOpen] = useState(false);
  const userPreference = usePreference(state => {
    return { currency: state.currency };
  });

  if (flag?.disable_currencies) {
    return null;
  }

  const text = shortVersion
    ? userPreference.currency
    : userPreference.currency === 'SEK'
    ? `SEK (kr)`
    : 'EUR (€)';

  return (
    <>
      {open ? (
        <CurrencyLanguageModal
          onClose={() => setOpen(false)}
          onCurrencyChange={onCurrencyChange}
        />
      ) : null}
      <Select>
        <SelectTrigger
          className={cn(
            'h-auto text-dark',
            shortVersion ? 'w-24' : 'w-28',
            className
          )}
          onClick={() => setOpen(true)}
        >
          {text}
        </SelectTrigger>
      </Select>
    </>
  );
};

export const LanguageDropdown = ({ shortVersion = false }) => {
  const lang = useContext(LanguageContext);

  const router = useRouter();

  const switchLocale = lang => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, lang },
    });
  };

  const selected = LANGUAGES.find(language => language.lang === lang);

  return (
    <>
      <Select
        onValueChange={lang => {
          switchLocale(lang);
        }}
        defaultValue={selected.lang}
      >
        <SelectTrigger className={cn('h-auto', shortVersion ? 'w-20' : 'w-28')}>
          <SelectValue>
            {shortVersion ? selected.flag : selected.trans}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          {LANGUAGES.map(language => (
            <SelectItem key={language.lang} value={language.lang}>
              {language.trans}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};
